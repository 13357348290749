<script setup>

const emits = defineEmits(["close"])

/** props **/
const props = defineProps({
  open: {
    default: false,
    required: true
  },
  borderRadius: {
    default: "0 0 0 0"
  },
  btnCloseVisible: {
    default: false,
  },
  zIndex: {
    default: 2,
  },
  top: {
    default: 'calc(58px + 20px)'
  }
})

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

/** watch **/
watch(() => props.open, (open) => {
  if (open) {
    document.body.style = "overflow: hidden"
  } else {
    document.body.style = "overflow: auto"
  }
})

/** methods **/
const handleClose = () => {
  emits("close")
}

/** lifecycle **/
onMounted(() => {

})
</script>

<template>
  <transition name="fade" mode="out-in">
    <div class="_modal-mobile" v-if="props.open && isMobile" @click="handleClose" :style="{zIndex}">
      <div class="_content" @click.stop :style="{borderRadius, marginTop: top ? top : 'calc(58px + 20px)'}">
        <img v-if="btnCloseVisible" class="_close" src="@/assets/img/icon-close-black.svg" alt="close" @click="handleClose">
        <slot></slot>
      </div>
    </div>
  </transition>
  <transition name="fade" mode="out-in">
    <div class="_modal-desktop" v-if="props.open && !isMobile" @click="handleClose" :style="{zIndex}">
      <div class="_content" @click.stop :style="{borderRadius}">
        <img v-if="btnCloseVisible" class="_close" src="@/assets/img/icon-close-black.svg" alt="close" @click="handleClose">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
@import "src/assets/config";

._modal-mobile {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);

  display: flex;
  justify-content: center;
  align-items: flex-start;

  ._content {
    //margin-top: calc($mobile-header-height + 20px);
    background-color: white;
    min-width: 300px; // download modal
    min-height: 200px;
    /* Shadow/lg */
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    position: relative;
    overflow: auto;

    ._close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
  }
}

._modal-desktop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);

  display: flex;
  justify-content: center;
  align-items: center;

  ._content {
    background-color: white;
    min-width: 375px;
    min-height: 200px;
    /* Shadow/lg */
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    position: relative;
    overflow: auto;

    ._close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
  }
}
</style>
